.sideBar {
    width: max-content;
    min-width: 212px;
    flex: 2;
    display: flex;
    background-color: #f0f1f2;
    /* background-color: #E1EDFF;
    background-image: url(../../resources//carelon/crl_supergraphic_linear_focus_rgb.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right; */
}
.sideBar.active {
    left: 0;
}
.sideBarContainer {
    width: 100%;
    height: 100%;
    position: relative;
    color: #231E33;
}
.sideBarTopSection {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 30px;
}
.sideBarTitle {
    font-size: 20px;
}
.sideBarClose {
    text-align: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 20px;
}
.sideBarClose:hover {
    cursor: pointer;
    background-color: rgb(235, 230, 230);
}
.sideBarContents {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
}
.sideBarLink {
    text-decoration: none;
    color: black;
}

@media only screen and (max-width: 720px) {
    .sideBarContents {
        margin: 2px;
    }
    .sideBar {
        flex: 0.8;
        width: 50px;
        min-width: 50px;
    }
}
@media only screen and (min-width: 1081px) {
    .sideBar {
        background-size: 85vh;
        background-position: -300px 200px;
    }
}