/*
* Slot machine design inspired by Flaticon
* model url: https://www.flaticon.com/free-icon/slot-machine_536079#term=slot%20machine&page=1&position=46
*/
.raffleContainer {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 2rem;
    align-self: center;
}
.Machine {
    font-family: "Roboto Slab", serif;
  }
  
  .Machine {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1;
    flex-direction: column;
    height: 400px;
    width: 750px;
    background-color: #38aa97;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin: 10vh auto 5vh auto;
    position: relative;
  }
  
  .Machine::before {
    content: "";
    position: absolute;
    width: 27px;
    height: 400px;
    background-color: #42938a;
    left: -20px;
    border-top-left-radius: 8px;
    z-index: -20;
  }
  
  .Machine-bottom {
    background-color: #e3ed5a;
    width: 320px;
    height: 30px;
    margin: auto;
    margin-top: -31px;
    margin-bottom: 30px;
    border-radius: 5px;
    bottom: -30px;
    position: absolute;
    right: -15px;
  }
  
  .Machine-bottom::before {
    position: absolute;
    content: "";
    left: -40px;
    bottom: 0;
    background-color: #cddd54;
    height: 30px;
    width: 60px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    z-index: -10;
  }
  
  .Lights {
    position: absolute;
    top: 0;
  }
  
  .Light-bulb {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: white;
    margin: 20px 5px;
    box-shadow: #eaeaea 0px 1px 5px;
    animation: toYellow 1s infinite;
  }
  .Light-bulb:nth-child(2n) {
    background-color: #f7b647;
    box-shadow: #f7b647 0px 1px 5px;
    animation: toWhite 1s infinite;
  }
  
  .Reels {
    background-color: #f7b647;
    width: 605px;
    padding: 20px 30px;
    border-radius: 8px;
    position: relative;
  }
  
  .Reels::before {
    content: "";
    position: absolute;
    width: 27px;
    height: 100%;
    background-color: #f9a02a;
    left: -20px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    z-index: -2;
    margin-top: -20px;
  }
  
  .Reel {
    display: inline-block;
    font-size: 58px;
    background-color: #eaeaea;
    border: #e3ed5a solid 0.5rem;
    border-radius: 5px;
    max-width: 80px;
    height: 80px;
    text-align: center;
    box-shadow: inset #3336 0px 1px 2px 1px;
    overflow: hidden;
  }
  .Reel-plate {
      width: 70px;
  }
  
  .Reel-plate span {
    display: block;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .Reel-plate-1 {
    animation: slideDown 1s;
  }
  
  .Reel-plate-1.spin {
    animation: spin 0.2s ease-out 0s 40;
  }
  
  .Reel-plate-2 {
    animation: slideDown 2s;
  }
  
  .Reel-plate-2.spin {
    animation: spin 0.2s ease-out 0s 70;
  }
  
  .Reel-plate-3 {
    animation: slideDown 3s;
  }
  
  .Reel-plate-3.spin {
    animation: spin 0.2s ease-out 0s 75;
  }
  .Reel-plate-4 {
    animation: slideDown 3s;
  }
  
  .Reel-plate-4.spin {
    animation: spin 0.2s ease-out 0s 40;
  }
  .Reel-plate-5 {
    animation: slideDown 3s;
  }
  
  .Reel-plate-5.spin {
    animation: spin 0.2s ease-out 0s 50;
  }
  .Reel-plate-6 {
    animation: slideDown 3s;
  }
  
  .Reel-plate-6.spin {
    animation: spin 0.2s ease-out 0s 60;
  }
  .Reel-plate-7 {
    animation: slideDown 3s;
  }
  
  .Reel-plate-7.spin {
    animation: spin 0.2s ease-out 0s 45;
  }
  
  .Slot {
    position: absolute;
    bottom: 45px;
    left: 15px;
    background-color: #eaf2ff;
    border-radius: 8px;
    padding: 15px 13px;
  }
  
  .Slot-inner {
    background-color: #175dc6;
    height: 40px;
    width: 15px;
    border-radius: 8px;
  }
  
  .raffleBtn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #ea2f12;
    border-radius: 5px;
    color: #fff;
    font-size: 32px;
    font-family: "Roboto Slab", serif;
    box-shadow: -5px 1px 0px 1px #af200a, -2px 2px #af200a, -2px 2px #af200a;
    border: 1px solid #af200a;
    text-shadow: black 1px 1px 2px;
    transition: all 0.3s ease-in-out;
    outline: none;
  }
  
  .raffleBtn:hover,
  .raffleBtn:focus {
    cursor: pointer;
    box-shadow: -2px 1px 0px 1px #af200a, -2px 2px #af200a, -2px 2px #af200a;
    outline: none;
  }
  
  .raffleBtn:focus {
    border: 1px solid #af200a;
  }
  
  .raffleBtn:disabled,
  .raffleBtn[disabled] {
    border: 1px solid #d3867b;
    background-color: #fba092;
    box-shadow: -2px 1px 0px 1px #d3867b, -2px 2px #d3867b, -2px 2px #d3867b;
    cursor: not-allowed;
    outline: none;
  }
  
  .Message {
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    color: #333;
  }
  
  .Alarm-container {
    position: absolute;
    top: -75px;
  }
  
  .Alarm {
    background-color: #f84f35;
    height: 76px;
    width: 80px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    position: relative;
    z-index: 0;
  }
  
  .Alarm::before {
    position: absolute;
    content: "";
    height: 76px;
    width: 35px;
    bottom: 0;
    left: -15px;
    background-color: #ea2f12;
    border-top-left-radius: 20px;
    z-index: -5;
  }
  
  .Alarm::after {
    background-color: #175dc6;
    height: 20px;
    width: 120px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    z-index: 10;
    position: absolute;
    content: "";
    bottom: 0;
    left: -27px;
  }
  
  .Alarm-bulb {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #f9a02a;
    position: absolute;
    bottom: 10px;
    left: 15px;
  }
  
  .Alarm.alert {
    animation: pulse 1s infinite;
  }
  
  .Alarm.alert .Alarm-bulb {
    animation: toYellow 1s infinite;
  }
  
  .Message {
    position: absolute;
    bottom: -400px;
    font-size: 26px;
    opacity: 0;
    width: 350px;
  }
  
  .Message-visible {
    opacity: 1;
    transition: opacity 4s ease-in;
  }

  .modalWinner {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 9;
  }
  .modalWinnerContainer{
    width: 50vw;
    height: 75vh;
    background-color: white;
    padding: 20px;
    position: relative;
    border-radius: 10px;
  }
  .actionCloseModal {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    color: black;
}
  .modalWinnerTitle {
    margin-top: -5rem;
    font-size: 30px;
    display: flex;
    align-content: center;
    justify-content: center;
    margin-bottom: 10px;
    text-transform: capitalize;
  }
  .modalWinnerForm {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    /* padding: 20px; */
  }
  .modalWinnerFormItem {
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .congratulations {
    padding-top: 30px;
    font-size: 24px;
    font-weight: bolder;
    text-align: center;
  }
  
  @keyframes toYellow {
    from {
      background-color: white;
      box-shadow: #eaeaea 0px 1px 5px;
    }
    to {
      background-color: #f7b647;
      box-shadow: #f7b647 0px 1px 5px;
    }
  }
  
  @keyframes toWhite {
    from {
      background-color: #f7b647;
      box-shadow: #f7b647 0px 1px 5px;
    }
    to {
      background-color: white;
      box-shadow: #eaeaea 0px 1px 5px;
    }
  }
  
  @keyframes pulse {
    from {
      background-color: #f84f35;
    }
    to {
      background-color: #ea2f12;
    }
  }
  
  @keyframes slideDown {
    from {
      margin-top: -150px;
    }
    to {
      margin-top: 0;
    }
  }
  
  @keyframes spin {
    0% {
      margin-top: -240px;
    }
    30% {
      margin-top: -200px;
    }
    50% {
      margin-top: -160px;
    }
    70% {
      margin-top: -80px;
    }
    90% {
      margin-top: 0;
    }
    100% {
      margin-top: -80px;
    }
  }
  
  @keyframes pulsePrize {
    from {
      transform: scale(1);
    }
    50% {
      transform: scale(0.85);
    }
    to {
      transform: scale(1);
    }
  }
  