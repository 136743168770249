.sideBarLink {
    text-decoration: none;
    color: black;
}
.sideBarItem {
    padding: 10px;
    display: flex;
    height: 28px;
    gap: 10px;
    justify-content: flex-start;
    border-radius: 10px;
}
.sideBarItem:hover, .sideBarItem>label:hover {
    cursor: pointer;
    background-color: rgb(254, 253, 255);
}
.sideBarItemIcon {
    height: 24px;
    width: 24px;
}

@media only screen and (max-width: 720px) {
    .sideBarItem {
        justify-content: center;
    }
    .sideBarItem>label {
        display: none;
    }
}