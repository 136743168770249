.login {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.upperSection, .lowerSection {
    display: flex;
    flex-direction: column;
}
.loginTitles {
    display: flex;
    width: max-content;
    align-items: center;
    flex-direction: column;
    color: #6726C3;
}
.loginCompanyLogo {
    width: 300px;
}
.appName {
    font-weight: 700;
    font-size: 18px;
}
.authHeader {
    position: absolute;
    width: 100vw;
    top: 0;
    background-color: #5009B5;
    color: white;
    text-align: center;
}