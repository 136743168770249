.footerContainer {
    height: 65px;
    width: 100%;
    background-color: #F5F5F5;
    margin: 0;
    padding: 0;
}
.copyrightContainer {
    align-items: baseline;
    display: flex;
    margin: 1rem 0 0;
}
.copyrightSection {
    font-size: 1rem;
    line-height: 1.625rem;
}
.copyrightSymbol, .ancilliary-text {
    color: #231e33;
    /* font-family: ElevanceSans; */
    font-size: .75rem;
    font-weight: 500;
    line-height: .875rem;
}
.copyrightAncilliaryText {
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
}
.ancilliary-full-container>a {
    text-decoration: none;
}
.separatorChar {
    color: #231e33;
    font-family: ElevanceSans;
    font-size: .75rem;
    font-weight: 500;
    height: 0.125rem;
    line-height: .875rem;
    margin: 0 1rem;
}