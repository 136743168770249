.formElementSelect {
    width: 12rem;
    padding: 1px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    box-sizing: border-box;
    margin-left: 1rem;
    margin-bottom: 1rem;
    height: 2rem;
    font-family: "Elevance Sans", sans-serif;
}