.dynamicFormsCardContainer {
    width: 200px;
    height: 100px;
    /* background-color: yellow; */
    border-radius: 10px;
    border-style: dashed;
    border-width: 2px;
    border-color: blueviolet;
    text-align: center;
}
.dynamicFormsCardContainer:hover {
    cursor: pointer;
    border-width: 3px;
}