.dynamicFormWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  position: relative;
}
.dynamicFormButtonsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}
.formRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.formElementInput{
    width: 12rem;
    border:1px solid;
    margin-bottom: 1rem;
    font-size: 16px;
    border-radius: 5px; 
    font-family: "Elevance Sans", sans-serif;
}
.formElementButton {
  border: none;
  padding: 10px 20px;
  background-color: #5009B5;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
}
.formCloseWrapper {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: black;
}
.formElementSelect option {
    font-size: 1rem;
}
.formElementLabel {
    margin-left: 2rem;
    margin-right: 2rem;
}

.formContainer {
  max-width: 400px; /* Adjust as needed */
  margin: 0 auto;
}

.formElement {
  margin-bottom: 20px;
}

.formElement label {
  display: block;
  margin-bottom: 5px;
}

.formElement input[type="text"],
.formElement input[type="password"],
.formElement input[type="email"],
.formElement input[type="number"],
.formElement textarea,
.formElement select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px; /* Rounded corners */
  font-size: 16px;
  box-sizing: border-box;
}

.formElement select {
  appearance: none; /* Remove default dropdown arrow */
  background-image: url('data:image/svg+xml;utf8,<svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><polygon points="10 0 0 6 0 15 20 15 20 6"></polygon></svg>');
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 16px auto;
  padding-right: 30px;
}

