.dynamicFormsHomeContainer {
    width: 100%;
    height: 100%;
    padding-left: 20px;
}
.dynamicFormsHomeContainer>p {
    padding-left: 10px;
}
.dynamicFormsCardSection {
    display: flex;
    flex-direction: row;
}