.sidePanelWrap {
    display: flex;
    height: 60vh;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    /* border: 1px solid black; */
}
.sidePanelTitle {
    margin-top: 10px;
    font-size: 2rem;
    font-weight: bold;
}
.sidePanelCounters {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 100%;
    font-size: 2rem;
    align-items: center;
    justify-content: space-evenly;
    background-color: white;
    border-radius: 30px;
}
.counterDate {
    font-size: 1rem;
}
.sidePanelButtons {
    display: flex;
    flex-direction: row;
    width: 70%;
    justify-content: space-around;
}
.sidePanelButtons>button {
    background-color: #5009B5;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 10px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-transform: capitalize;
}
.sidePanelButtons>button:disabled {
    background-color: #b59cd7;
    cursor: not-allowed;
}
@media only screen and (max-width: 600px) {
    .sidePanelWrap {
        height: 20vh;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        justify-content: space-evenly;
        /* border: 1px solid black; */
    }
    .sidePanelTitle {
        font-size: 1.5rem;
        font-weight: bold;
    }
    .sidePanelCounters {
        display: flex;
        flex-direction: row;
        width: 80%;
        height: 20%;
        font-size: 1rem;
        align-items: center;
        justify-content: space-evenly;
        background-color: white;
        border-radius: 30px;
    }
}