.homeWingContainer {
    flex: 2;
    /* background-color: #E1EDFF; */
    background-color: #f0f1f2;
    margin-left: 1.5rem;
}
.homeWingTopSection {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    font-size: 30px;
    place-content: center;
    justify-content: flex-start;
}
.homeWingTitle {
    font-size: 20px;
    padding: 10px;
    display: flex;
    height: 28px;
    gap: 10px;
    border-radius: 10px;
    
}

.homeWingIcon {
    height: 24px;
    width: 24px;
}
.radio-userStatus {
    font-size: 14px;
}