.banner {
    height: 35vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}
.kickoff {
    background-image: url('../../resources/carelon/kickOffBanner.jpg');
}
.townhall {
    background-image: url('../../resources/carelon/leaders-townhall.png');
}