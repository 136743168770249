.homeMainBox {
    flex: 6;
    display: flex;
    flex-direction: column;
    /* min-height: 100vh; */
    place-items: center;
    /* place-content: center; */
    /* background-image: url(../../resources//carelon/crl_linear_connection_rgb.png);
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: 800px; */
}
.withBanner {
    min-height: calc(100vh - 320px);
}
.noBanner {
    min-height: calc(100vh - 64px);
}
.appsWrapper {
    width: 400px;
    height: 50vh;
    display: flex;
    flex-direction: row;
    place-items: center;
    place-content: center;
}
.appItem {
    padding: 10px;
    height: 80px;
    width: 80px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    place-items: center;
    place-content: center;
}
.appItemIcon {
    height: 50px;
    width: 50px;
}
.appItem:hover, .appItem>label:hover, .appItemIcon:hover {
    cursor: pointer;
}
.appItem:hover {
    border: solid gray 1px;
}
@media only screen and (max-width: 1080px) {
    .homeMainBox {
        background-position: 550px;
    }
}