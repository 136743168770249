.landingMenuItem {
    display: flex;
    flex-direction: column;
    text-align: center;
    text-decoration: none;
    color: black;
}
.iconWrap {
    position: relative;
    width: 70px;
    height: 70px;
    background-color: rgb(225, 223, 223);
    border-radius: 35px;
}
.placeHolderIcon {
    position: absolute;
    top: -15px;
    right: -10px;
    width: 100px;
}