.checkInContainer {
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 70%;
    /* background-color: #0074d9; */
    border: 1px solid #0074d9;
    border-radius: 10px;
    gap: 12px;
}
.checkInContainer .title {
  text-align: center;
  font-size: 1.5rem;
}
.verifySection {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: space-around;
  gap: 1rem;
}
.associateCheckIn {
  font-size: 32px;
  margin-top: 15rem;
  font-family: "Elevance Sans", sans-serif;
}

.inputContainer .verifyInput.domainId {
  text-transform: uppercase;
  width: 40%;
  height: 30px;
  padding: 10px;
  font-size: 1rem;
  text-align: center;
}
  
  .inputContainer button, .checkInBtn, .cancelCheckInBtn {
    background-color: #5009B5;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 10px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-transform: capitalize;
  }
  .inputContainer button:disabled, .checkInBtn:disabled {
    background-color: #b59cd7;
    cursor: not-allowed;
  }
  .assocInfoWrapper {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
  .textAreaWrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      align-self: center;
      width: 70%;
  }
  .dataPanel {
    display: flex;
    justify-content: center;
    width: 90%;
    height: 200px;
    margin-bottom: 1rem;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 1rem;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .dataTextBox {
    width: 90%;
    height: 200px;
    border: none;
    resize: none;
    line-height: 1.5;
    overflow-y: auto;
    text-align: center;
    font-size: 16px;
    background-color: #f9f9f9;
  }
  
  .successMessage {
    font-size: 24px;
    display: flex;
    height: 100%;
    text-align: center;
  }
  .actionBtnContainer {
    display: flex;
    width: 100%;
    height: fit-content;
    flex-direction: column;
    align-self: center;
  }
  .errorMsg, .iconError {
      color: red;
      font-size: 1rem;
      text-align: center;
  }
  .successMsg, .iconSuccess {
    margin-top: 1rem;
    color: green;
    font-size: 16px;
  }
  .actionBtnWrapper, .inputContainer {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-evenly;
  }
  .actionBtnWrapper {
    align-self: center;
    width: 50%;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  .successWrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 60px;
      align-items: center;
  }
  @media only screen and (max-width: 600px) {
    .textAreaWrapper {
      width: 95%;
      height: 40%;
    }
    .dataPanel {
      width: 100%;
      height: 220px;
    }
    .dataTextBox {
      width: 100%;
      height: 220px;
    }
    .actionBtnWrapper {
      width: 100%;
      justify-content: space-evenly;
    }
  }